const theme = {

  // --- Built-ins ---

  colors: {
    text: "#000",
    textDark: ["#000", "#232323", "#444", "#666"],
    textLight: ["#fff", "#f1f1f1", "#c0c0c0", "#9a9a9a", "#848484", "#777"],
    background: "#fff",
    backgroundDark: ["hsl(214, 3%, 10%)", "#222"],
    backgroundLight: ["#fff", "hsl(27, 5%, 94%)", "hsl(27, 60%, 90%)"],
    primary: "hsl(14, 100%, 57%)", // orange #FF5724
    primaryDark: "hsl(27, 60%, 10%)", // dark brown #29180a
    secondary: "hsl(206, 100%, 35%)", // blue #005494
    secondaryDark: "hsl(214, 72%, 8%)", // dark blue #061223
    accent: "hsl(345, 99%, 57%)", // pink #FE255B
    muted: "#ddd", // use for pagination button borders
  },
  fonts: {
    body: "Archivo, 'Helvetica Neue', sans-serif",
    heading: "inherit",
    monospace: "'Ubuntu Mono', monospace",
  },
  fontSizes: {
    tiny: 12,
    code: 13,
    control: 14,
    body: 16,
    h6: 16,
    ingress: 18,
    h5: 18,
    h4: 21,
    h3: 26,
    h2: 34,
    h1: 42,
    huge: 48,
  },
  fontWeights: {
    body: 400,
    control: 600,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.4,
    h2: 1.2,
    h1: 1.1,
  },
  breakpoints: ["512px", "768px", "1024px"], /* Must be strings */
  sizes: {
    container: 1024 /* Used in <Container> */
  },
  space: [0, 4, 8, 12, 16, 24, 32, 40, 64, 96, 128],
  shadows: {
    light: "0 0 30px 0 rgba(0,0,0,0.34)",
    heavy: "0 0 30px 0 rgba(0,0,0,0.55)"
  },

  // --- HTML element styles ---

  styles: {
    root: {
      fontFamily: "body",
      fontSize: "body",
      fontWeight: "body",
      lineHeight: "body",
      color: "textDark.3",
    },
    h1: {
      variant: "text.heading",
      fontSize: "h1",
      lineHeight: "h1",
      letterSpacing: "-0.13rem"
    },
    h2: {
      variant: "text.heading",
      fontSize: "h2",
      letterSpacing: "-0.1rem"
    },
    h3: {
      variant: "text.heading",
      fontSize: "h3",
      letterSpacing: "-0.06rem"
    },
    h4: {
      variant: "text.heading",
      fontSize: "h4",
      fontWeight: "body",
      letterSpacing: "-0.04rem"
    },
    h5: {
      variant: "text.heading",
      fontSize: "h5",
      fontWeight: "body",
      letterSpacing: "-0.01rem"
    },
    h6: {
      variant: "text.heading",
      fontSize: "h6",
      letterSpacing: "-0.02rem", // tighter than h5 because bold
      marginTop: 4,
      marginBottom: 1,
      color:  "textDark.2",
    },
    p: {
      marginTop: 0,
      marginBottom: 3,
      img: {
        verticalAlign: "text-top",
      }
    },
    img: {
      borderRadius: "4px",
    },
    figure: {
      mx: 0,
      my: 5,
    },
    figcaption: {
      fontWeight: "control",
      marginTop: 2
    },
    a: {
      variant: "links.inline",
      code: {
        // Make sure code spans in links stay blue/orange
        color: "inherit",
      },
    },
    pre: {
      fontFamily: "monospace",
      color: "textDark.1",
      marginTop: 3,
      marginBottom: 5,
      padding: 2,
      overflowX: "auto",
      background: "backgroundLight.1",
      borderRadius: "4px",
    },
    code: {
      fontFamily: "monospace",
      color: "textDark.1",
      padding: 0,
      borderRadius: 0,
      whiteSpace: "pre",
    },
    blockquote: {
      paddingLeft: 3,
      /* Top/bottom */
      marginBlockStart: 0,
      marginBlockEnd: 3,
      /* Left/right */
      marginInlineStart: 0,
      marginInlineEnd: 0,
      borderLeftWidth: "2px",
      borderLeftStyle: "solid",
      borderColor: "textDark.3",
    },
    table: {
      borderCollapse: "collapse",
      marginTop: 5,
      marginBottom: 5,
      borderRadius: "4px",
      overflow: "hidden"
    },
    thead: {
      background: `linear-gradient(
        to right,
        hsl(14, 100%, 57%),
        hsl(345, 99%, 57%)
      )`
    },
    tbody: {
      backgroundColor: "hsl(214, 100%, 98%)",
      background: `linear-gradient(
        to right,
        hsl(14, 80%, 98%),
        hsl(345, 80%, 98%)
      )`
    },
    th: {
      fontWeight: "control",
      px: 4,
      py: 2,
      color: "textLight.1",
      borderBottomColor: "backgroundLight.2",
      borderBottomWidth: "thin",
      borderBottomStyle: "solid"
    },
    td: {
      px: 4,
      py: 2,
      paddingBottom: 1,
      borderBottomColor: "hsla(0, 0%, 0%, 0.1)",
      borderBottomWidth: "thin",
      borderBottomStyle: "solid"
    },
    // This textIndent nonsense was the only way of getting the bullet alignment the same
    // in Safari as in the other browsers.
    ul: {
      listStyleType: "none",
      paddingLeft: 7,
      li: {
        textIndent: "-18px",
      },
      "li::before": {
        content: "'»'",
        fontWeight: 600,
        marginRight: "0.5em"
      }
    },
    ol: {
      listStylePosition: "inside",
      paddingLeft: 7,
      li: {
        textIndent: "-18px",
      },
      "li::marker": {
        fontWeight: 600
      }
    },
  },

  // --- Variants ---

  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "h2",
      marginTop: 7,
      marginBottom: 2,
      color: "textDark.1",
      "& a": {
        color: "inherit"
      },
    },
  },
  links: {
    inline: {
      color: "secondary",
      textDecoration: "none",
      transition: "color .2s",
      "&:hover": {
        color: "primary",
      },
      "&:active": {
        color: "accent",
      },
      "&:focus": {
        backgroundColor: "backgroundLight.2",
        outline: 0,
      },
    },
    nav: {
      variant: "links.inline",
      display: "inline-block",
      fontSize: "h5",
      letterSpacing: "-0.04rem",
      color: "textLight.3",
      "&:focus": {
        color: "accent",
        outline: 0,
      },
      "&.current": {
        color: "textLight.1",
      }
    }
  },
  buttons: {
    primary: {
      fontFamily: "inherit",
      fontWeight: "control",
      fontSize: "control",
      whiteSpace: "nowrap",

      color: "primary",
      backgroundColor: "transparent",
      borderColor: "primary",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "5px",
      px: 6,
      py: 3,

      textAlign: "center",
      textDecoration: "none",
      cursor: "pointer",
      userSelect: "none",
      transition: "color .2s ease-in 0s, " +
        "border-color .2s ease-in 0s, " +
        "background-color .2s ease-in 0s",

      "&:hover": {
        color: "accent",
        borderColor: "accent",
      },
      "&:focus": {
        color: "accent",
        borderColor: "accent",
        outline: 0,
      },
      "&:active": {
        color: "textLight.0",
        backgroundColor: "accent",
        borderColor: "accent",
      },
      "&:disabled": {
        color: "textLight.2",
        borderColor: "textLight.2",
        cursor: "default",
        pointerEvents: "none"
      },
      "&.current": {
        color: "textLight.0",
        backgroundColor: "primary",
        borderColor: "primary",
      },
    },
    secondary: {
      variant: "buttons.primary",
      color: "textDark.2",
      borderColor: "textDark.2",
    },
    small: {
      variant: "buttons.primary",
      px: 5,
      py: 2,
    }
  },
  section: {
    px: [4, 5, 6],
    py: [8, 8, 9]
  },
  forms: {
    /* Used in <Input> */
    input: {
      width: "auto",
      fontFamily: "inherit",
      fontSize: "control",
      fontWeight: "control",
      bg: "backgroundLight.1",
      borderColor: "textDark.3",
      p: 3,
      transition: "all .2s ease-in 0s",

      "&:focus": {
        borderColor: "primary",
        boxShadow: "0 0 0 0.2rem hsla(14, 100%, 57%, 0.25)",
        outline: "none"
      },

      "&::placeholder": {
        color: "textLight.2"
      }
    }
  },
  ul: {
    flush: {
      paddingLeft: "20px",
    }
  },
  ol: {
    flush: {
      paddingLeft: "20px",
    }
  }
};

export default theme;
